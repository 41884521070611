<script lang="ts">
  import FAQ from "./faq.svelte";
  import EmailChangeSteps from "@images/email-change-step.png?raw";
  import FAQElement from "./faq-element.svelte";
  import { Image } from "@components/ux/image";
</script>

<FAQ title={"League of Legends Accounts"}>
  <FAQElement open={true} title="Is it safe to buy accounts?">
    Yes. It is completely safe and secure to <b>buy a lol account</b>. We use
    Stripe as our main payment processor to ensure safety and privacy of all of
    our customers. This means that all your payment details are processed by
    Stripe and are not accessible by us. There is no risk whatsoever in buying a
    League of Legends account from us.
  </FAQElement>
  <FAQElement title="How can I change my LoL account credentials?">
    <p>
      All of our <b>League of legends Accounts</b> are created with unverified
      Emails. This means that you can change the Email and Password without
      needing the original Email the account was created with. In order to
      change your Email and/or Password, All you need to do is: <br />
      Login to{" "}
      <a
        class="text-blue-600"
        target="_blank"
        href="https://leagueoflegends.com"
        rel="noopener noreferrer">
        https://leagueoflegends.com
      </a>
      <br />
      Click on &quot;Your Username&quot; -&gt; &quot;Settings&quot; located in top
      right corner.
      <br />
      Change the Email address by clicking on the email address field. (You will
      see the old email address there, just click on it and it will work) <br />
      <Image
        src={EmailChangeSteps}
        alt="Email change"
        class="my-4 inline-block" />
      <br />
      Change the password from the same page.
    </p>
  </FAQElement>
  <FAQElement title="How fast will I receive my LoL account?">
    Our fully automated instant delivery system will have your <b
      >League of Legends smurf account</b> details delivered to your inbox instantly.
    No more waiting for your account!
  </FAQElement>
  <FAQElement title="The account is not as described (Blue essence wise)">
    <p>
      We like to leave FULL power to our customers. Some customers like champion
      capsules, others like pure Blue Essence. Therefore we leave the champion
      capsules UNTOUCHED. If you want pure Blue Essence then just head to your
      loot and open all champion capsules, disenchant all the champion shards
      and use the mission tokens to buy champion shards and disenchant them as
      well. Who knows maybe you like a champion or two and decide not to
      disenchant those.
      <br />
      <br />
      <span class="font-bold text-green-600">
        The loot estimate is using the actual value of the champion and not the
        disenchanted value, this is why we recommend using the champion shards
        instead of disenchanting.
      </span>
    </p>
  </FAQElement>
  <FAQElement title="I bought the account, where is the account information?">
    <p>
      Once you buy an account make sure to check your email for a &quot;Your
      Order is complete&quot; email. Open that email and the account info is
      inside. You will also find the account information on the website after
      payment completion.
    </p>
  </FAQElement>
  <FAQElement title="I got banned, how can I get a replacement?">
    <p>
      Every <b>League of Legends account</b> comes with a{" "}
      <span class="font-bold text-green-600"> lifetime warranty </span>{" "}
      that allows you to get replacements. The warranty only applies to accounts
      banned for botting, bans for flamming and other types are NOT covered by the
      warranty. <br /> You can get a replacement within seconds by following
      these steps: Join our discord server using{" "}
      <a
        href="https://discordapp.com/invite/aMe2WfP"
        class="font-bold text-blue-600"
        target="_blank"
        rel="noopener noreferrer">
        this link
      </a>{" "}
      or using the &quot;Get Help&quot; Button at the top of the page.
      <br />
      Open a ticket using the{" "}
      <span class="text-blue-600">#support-ticket</span> channel and by clicking
      on Envelope icon.
      <br />
      Follow the instructions provided by the Bot and you should get a replacement
      sent to your Email{" "}
      <span class="font-bold text-green-600">instantly</span>.
      <br /> We must have access to your banned account in order to replace it.
      <br />
      <br /> You CANNOT benefit from the warranty if you change the username used
      to login, however changing the ingame name is totally fine.
    </p>
  </FAQElement>
  <FAQElement title="What are the regions of the LoL accounts that you sell?">
    To ensure that all of our customers are satisfied, we sell lol smurf
    accounts in every region; EUW, EUNE, NA, TR, OCE, BR, LAN, and LAS - just
    choose your desired region and purchase your lol account.
  </FAQElement>

  <FAQElement title="How many LoL Smurfs can I buy?">
    Unlimited! You can buy as many lol accounts as you desire. We are
    continuously restocking our accounts inventory to make sure that we have
    more than enough accounts for our customers.
  </FAQElement>
</FAQ>
